<template>
    <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
    <div class="wrapper">Website is WIP</div>
    <div class="loading">
      <img class="img" src="/imgs/placeholder.jpg" alt="loading...">
      <div class="wrapper">
      <div class="progress-grey">
        <div ref="prog2" class="progress-color"></div>
        </div>
    </div>
    </div>
  </template>

<style>
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 62.5%;
    }
     .loading {
      position: absolute;
      height: 99vh;
      width: 99vw;
      text-align: center;
      line-height: 99vh;
    }
    .img {
        display: flex;
      position: relative;
      margin: auto;
      margin-top: 20%;
      width: 10rem;
    }
    .wrapper {
        display:flex;
        justify-content: center;
        padding: 1%;
    }

    .progress-grey {
        width: 20%;
        border-radius: 30px;
        background-color: #DADFF7;
    }
    
    .progress-color {
        border-radius: 30px;
        width: 1%;
        height: 30px;
        background-color: #F57266;
    }
    </style>

<script>
    export default {
        data() {
            return {
                
            }
        },
        mounted() {
            this.$refs.prog2
        },
        methods: {
            async load() {
                var delay = ms => new Promise(res => setTimeout(res, ms));
                const bar2 = this.$refs.prog2;
                var width = 1;
                while(width < 100) {
                    await delay(25)
                    width++;
                    bar2.style.width = width + "%";
                }
            }
        },
        created() {
            window.addEventListener('load', this.load) 
        }
    }
</script>