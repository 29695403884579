<template>
    <h1 class="contact-head" ref="works" data-aos="fade-up" data-aos-duration="4500" @focus="logo_appear()">Works</h1>
    <div class="works-showcase" data-aos="fade-up" data-aos-duration="6000">
        
    </div>

</template>

<script>
    import works from "../works.json"

    export default {
        data() {
            return {
                
            }
        },
        mounted() {
            this.addworks()
        },
        methods: {
            addworks() {
                const insert = async function () {
                    try {
                        const response = works
                        console.log(response)
                        response.works.forEach((element) => {
                            const path = element.img
                            document.querySelector("div.works-showcase").insertAdjacentHTML("beforeend", `
                            <div class="work-container">
                                <div class="work-wrapper">
                                <div class="work-imgs" style="background-image:url(${path})">
                                    <div class="work-popup">
                                        <div class="work-text">
                                            <h1 class="work-title">${element.name}</h1>
                                            <p class="work-desc">${element.description}</p>
                                        </div>
                                        <div class="work-btns">
                                            <a class="work-btn" v-if="${element["contains-demo"]}" != null" href=${element["demo-link"]}>View</a>
                                            <a class="work-btn" href=${element["github-link"]}>Github</a>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                                `)
                            console.log(element)
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }
                insert()
            }
        }
    }
</script>

<style>

    .works-showcase {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .work-container {
        width: 15vw;
        height: 50%;
        overflow: hidden;
        border-radius: 30px;
        
    }

    /* .work-wrapper {
        width: 15vw;
        height: 75vh;
        border-radius: 30px;
    } */
    .work-imgs {
        width: 12vw;
        height: 75vh;
        border-radius: 30px;
        object-fit: cover;
    }

    .work-popup {
        position: absolute;
        width: 12vw;
        height: 75vh;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 30px;
        transition: 0.2s ease-in-out;
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .work-popup:hover {
        opacity: 1;
    }

    .work-btn {
        width: 10vw;
        height: 5vh;
        border-radius: 30px;
        background-color: #fff;
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        flex-direction: columns;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin: 10px;
    }

    .work-title, .work-desc {
        margin: 15px;
    }

    @media screen and (max-width: 800px) {
        .works-showcase {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
        .work-container {
            width: 80vw;
            height: fit-content;
            padding-bottom: 1rem;
        }

        .work-imgs {
            width: 80vw;
            height: 15vh;
            border-radius: 30px;
            object-fit: cover;
        }

        .work-popup {
            width: 80vw;
            height: 15vh;
            border-radius: 30px;
            object-fit: cover;
            flex-direction: row;
        }

        .work-text {
            width: 12rem;
        }

        .work-btns {
            width: 6rem;
        }

        .work-btn {
            width: 5rem;
        }
    }
</style>