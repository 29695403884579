<template>
   <h1 class="contact-head" ref="skills" data-aos="fade-up" data-aos-duration="4500">Relevant Skills</h1> 
   <div class="skills-showcase" data-aos="fade-up" data-aos-duration="6000">
        
    </div>
  </template>

<script>
    import skills from "../works.json"

    export default {
        data() {
            return {
                
            }
        },
        mounted() {
            this.addskills()
        },
        methods: {
            addskills() {
                const insert = async function () {
                    try {
                        const response = skills
                        console.log(response)
                        response.skills.forEach((element) => {
                            const path = element.img
                            document.querySelector("div.skills-showcase").insertAdjacentHTML("beforeend", `
                            <div class="skills-container">
                                <img class="skill-img" src=${path}">

                            </div>
                            `)
                            console.log(element)
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }
                insert()
            }
        }
    }
</script>

<style>
    .skills-showcase {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 calc(25% - 10px);
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }
    .skills-container {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 calc(25% - 10px);
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        width: 20%;
        height: 20%;
    }
    .skill-img {
        height: 10vw;
    }

    @media screen and (max-width: 800px) {

        .contact-head {
            align-items: center;
            text-align: center;
        }
    }
</style>