
<script>
    import * as THREE from 'three'
    import * as dat from 'lil-gui'
    import CANNON, { Vec3 } from 'cannon'

    THREE.ColorManagement.enabled = false

    // Debugging UI
    // const debugUI = new dat.GUI()
    // const debugItems = {}

    const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
    }

    // Base world to test
    const canvas = document.querySelector('canvas.webgl')
    const scene = new THREE.Scene()
    const textureLoader = new THREE.TextureLoader()
    const sapphireTexture = textureLoader.load('/textures/Sapphire_001_NORM.jpg')

    const mesh = new THREE.Mesh(
        new THREE.SphereGeometry(1, 20, 20),
        new THREE.MeshStandardMaterial({color: "0xffffff", map: sapphireTexture})
    )
    // scene.add(mesh)

    const directionalLight = new THREE.DirectionalLight('#ffffff', 0.9)
    scene.add(directionalLight)
    directionalLight.position.set(1, 0, 1)

    // Camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.z = 3
    scene.add(camera)

    // Resize Control
    window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

    const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: '#777777',
        metalness: 0.3,
        roughness: 0.4,
        envMapIntensity: 0.5
    })
)
    floor.receiveShadow = true
    floor.rotation.x = - Math.PI * 0.5
    scene.add(floor)

    // Renderer
    const renderer = new THREE.WebGLRenderer({
    canvas: canvas
    })
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    const clock = new THREE.Clock()
    var oldElapsedTime = 0
    // Good ole tick function for good measure
    const tick = () => {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - oldElapsedTime
        oldElapsedTime = elapsedTime

        mesh.rotation.x += Math.sin(deltaTime/4)
        mesh.rotation.y += Math.sin(deltaTime/2)

        mesh.position.set(Math.sin(elapsedTime), Math.cos(elapsedTime), Math.cos(elapsedTime))

        // Update renders and call tick again
        renderer.render(scene, camera)
        window.requestAnimationFrame(tick)
    }

    tick()


    export default {
        data() {
            return {
                
            }
        }}
</script>