

<template>
    <head>
        <meta charset="utf-8">
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <title>Ivan Portfolio</title>
    </head>
    
    <loading v-if="isLoading"></loading>
    <div v-if="!isLoading">
        <svg class="background--custom" id="demo" viewBox="0 0 100 100" preserveAspectRatio="none">
      <path fill="#191916" fill-opacity="0.8" d="M-100 -100L200 -100L200 70L-100 70Z" style="animation: path0 3.52112676056338s linear infinite alternate;" /><path fill="#101616" fill-opacity="0.7" d="M-100 -100L200 -100L200 50L-100 50Z" style="animation: path1 12.5s linear infinite alternate;" /><path fill="#000000" fill-opacity="0.2" d="M-100 -100L200 -100L200 20L-100 20Z" style="animation: path2 30s linear infinite alternate;" />
    </svg>
        <div ref="nav" class="topnav">
            <div class="navleft">
                <button @click="scroll(1)" class="home" href="">Home</button>
            </div>
            <div class="navright">
                <button @click="scroll(2)" class="navright-child" href="#">About</button>
                <button @click="scroll(3)" class="navright-child" href="#">Skills</button>
                <button @click="scroll(4)" class="navright-child" href="#">Works</button>
                <button @click="scroll(5)" class="navright-child" href="#">Contact</button>
            </div>
        </div> 

        <!-- THREE JS HERE -->
        
        <three></three>

        <header>{{ my_name }}</header>
        <div ref="about" class="about">
            <h1 class="section-head">About Me</h1>
            <div class="about-pic">
                <img class="about-pic1" data-aos="fade-up" data-aos-duration="3500" src="/imgs/myface.jpg" alt="placeholder">
            </div>
            <div class="about-text" data-aos="fade-up">
                <li class="section-body">Hi! My name is <b>Ivan Zheng</b>!</li>
                <li class="section-body">I am currently a <b>Sophomore</b> majoring in Computer Science at the Honors College of Rutgers University.</li>
                <li class="section-body">Currently, I am a Rutgers Computing Academic Tutoring (RUCATS) Tutor, a member of RUMAD (Mobile Application Design), 
                    and a GenHC (Program for First Gen College Students) Mentor.</li>
                <li class="section-body">I currently work as a Computer Support Technician for the Old Queens Quad at Rutgers New Brunswick servicing
                     the President's, Chancellor's and Provost's, and General Council Offices.</li>
                <li class="section-body">Previously, I worked as a Software Engineer and IT Technician for Staten Island Tech</li>
                <li class="section-body">My hobbies include Badminton, Volleyball, Games, Anime, Learning new things, and debugging. :)</li>
            </div>
            
        </div>
        <div ref="skills">
            <skills></skills>
    </div>
        <div ref="works">
            <works></works>
    </div>

        <div ref="contact" class="contact">
        <logo ref="logos"/>
        </div>
        
    </div>
</template>

<script>
import loading from "./components/loading.vue";
import logo from "./components/logo.vue";
import skills from "./components/skills.vue";
import works from "./components/works.vue";
import three from "./components/three.vue";
import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';

    export default {
        data() {
            return {
                isLoading: false,
                my_name: "",
                
            }
        },
        components: {
            loading,
            logo,
            skills,
            works,
            three
        },
        mounted() {
            this.$refs.nav
            this.$refs.about
            this.$refs.skills
            this.$refs.works
            this.$refs.contact
            AOS.init()
            setTimeout(async () => {
                this.isLoading = false;
                var delay = ms => new Promise(res => setTimeout(res, ms));
                const name = ["I", "v", "a", "n", " ", "Z", "h", "e", "n", "g"];

                var i = 0;
                    while (i < name.length) {
                        await delay(150);
                        this.my_name += name[i];
                        i = i +1;
                        // console.log(this.isLoading)
                    }}, 150);
        },
        methods: {
            nav_scroll() {
                const navbar = this.$refs.nav;
                // console.log(window.scrollY)
                // console.log(navbar.offsetTop)
                if(window.scrollY > navbar.offsetTop) {
                    navbar.classList.add("sticky");
                } else {
                    navbar.classList.remove("sticky");
                }
            },
            scroll(location) {
                if(location == 1) {
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                } 
                if(location == 2) {
                    window.scroll({ top: this.$refs.about.offsetTop, left: 0, behavior: 'smooth' });
                }
                if(location == 3) {
                    window.scroll({ top: this.$refs.skills.offsetTop, left: 0, behavior: 'smooth' });
                }
                if(location == 4) {
                    window.scroll({ top: this.$refs.works.offsetTop, left: 0, behavior: 'smooth' });
                }
                if(location == 5) {
                    window.scroll({ top: this.$refs.contact.offsetTop, left: 0, behavior: 'smooth' });
                }
            }
        },
        beforeMount() {
            this.logo_appear


        },
        created(){
            window.addEventListener('scroll', this.nav_scroll);
            // window.addEventListener('scroll', this.logo_appear, {once:true});
        },
    }
</script>