<template>
    <div ref="contact" class="contact">
                  <h1 class="contact-head" data-aos="fade-up" data-aos-duration="3500" @focus="logo_appear()">Contacts</h1>
                  <div class="contact-logos" id="contact-logos">
                  </div>
          </div>
  </template>

<script>
export default {
  data() {
    return {
      
    }
  },
  mounted() {
    this.logo_appear()
  },

  methods: {
    logo_appear() {
                const logos = {
                    phone : {
                        alt: "telephone logo",
                        img: "https://i.postimg.cc/Hn8WRB6v/telephone-handle-silhouette.png",
                        site: ""
                    },
                    insta : {
                        alt: "instagram logo",
                        img: "https://i.postimg.cc/NGK1QtVS/instagram.png",
                        site: "https://www.instagram.com/ivan.zhengg/"
                    },
                    linkedin : {
                        alt: "linkedin logo",
                        img: "https://i.postimg.cc/BtWZ0HyS/linkedin.png",
                        site: "https://www.linkedin.com/in/ivan-zheng7644/"
                    },
                    github : {
                        alt: "github logo",
                        img: "https://i.postimg.cc/CBbg1rtF/github.png",
                        site: "https://www.github.com/IvanZ505"
                    }
                }
                for(var key in logos) {
                    var path = logos[key].img;
                    console.log(path);
                    document.querySelector('#contact-logos').insertAdjacentHTML(
                            "beforeend",
                            `<a href=${logos[key].site}>
                                <img class="logo-img" src=${path} alt=${logos[key].alt}>
                                </a>`
                    )

                }
            }
        }
}
</script>